<template>
  <page-layout
    :title="title"
    :sub-title="$t('user_group_management.view_user_groups_layout.sub_title')"
    :has-back-button="true"
    :emit-back="true"
    @back="back"
  >
    <template #header-actions>
      <base-button
        v-if="showSaveDetailsButton"
        :key="key"
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="$refs.routerView.saving"
        :disabled="!$refs.routerView.dirty"
        @click="$refs.routerView.handleSaveChanges"
      />
    </template>
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span"><i v-if="tab.icon" :class="tab.icon"></i> {{ tab.label }}</span>
      </el-tab-pane>
    </el-tabs>
    <router-view ref="routerView"/>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { mapGetters } from 'vuex'
import ViewUserGroup from '../mixins/ViewUserGroup'
import TabNavigation from '../mixins/TabNavigation'

export default {
  name: 'UserGroupViewLayout',
  components: {
    PageLayout
  },
  mixins: [ViewUserGroup, TabNavigation],
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: '',
      activeName: '',
      showSaveDetailsButton: false
    }
  },
  methods: {
    showButtons () {
      this.showSaveDetailsButton = !!this.$route.meta.showSaveDetailsButton
      this.key = this.$key()
    },
    back () {
      if (this.$refs.routerView && this.$refs.routerView.goBack) {
        return this.$refs.routerView.goBack()
      } else {
        this.$router.push({ name: 'UserGroupsViewAll' })
      }
    }
  },
  computed: {
    ...mapGetters({}),
    title () {
      return this.userGroupName
    },
    tabs () {
      return [
        {
          label: this.$t('general.label.members'),
          name: 'UserGroupMembers',
          icon: 'ri-money-euro-circle-line'
        },
        {
          label: this.$t('user_group_management.view_user_groups_layout.tab_label.products'),
          name: 'UserGroupProducts',
          icon: 'ri-pin-distance-line'
        },
        {
          label: this.$t('general.input_label.description'),
          name: 'UserGroupDetails',
          icon: 'ri-profile-line'
        }
      ]
    }
  }
}
</script>

<style lang="scss">

</style>
