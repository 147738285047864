<template>
  <page-layout
    :title="$t('user_group_management.user_group_add_page.title')"
    :has-back-button="true"
    :emit-back="true"
    @back="goBack"
  >
    <template #header-actions>
      <base-button
        :label="$t('user_group_management.user_group_add_page.button_add_label')"
        type="primary"
        :loading="saving"
        :disabled="!dirty"
        @click="handleSaveChanges"
      />
    </template>
    <el-alert
      type="info"
      :closable="false"
      show-icon
    >
      <span
        v-html="$t('user_group_management.user_group_add_page.alert')"/>
    </el-alert>
    <base-section :title="$t('user_management.section.user_group.title')">
      <el-form
        ref="form"
        :key="key"
        :model="model"
        id="appUserForm"
        autocomplete="off"
        label-position="top"
        @submit.prevent.native="handleSaveChanges"
      >
        <base-input
          :label="$t('general.input_label.name')"
          maxlength="140"
          v-model.trim="model.data.extId"
          prop="name"
          name="name"
          :required="true"
          :max-width="400"
          :validations="$v.model.data.extId"
          @submit="handleSaveChanges"
        />
        <base-input
          type="textarea"
          :label="$t('general.input_label.description')"
          maxlength="140"
          :autosize="{minRows: 5, maxRows: 8}"
          v-model.trim="model.data.description"
          prop="description"
          name="description"
          :show-word-limit="true"
          :placeholder="$t('user_management.view_app_users_page.add_expense.input_description.placeholder', {number: 140})"
          :max-width="400"
          :required="true"
          :validations="$v.model.data.description"
          @submit="handleSaveChanges"
        />
      </el-form>
    </base-section>
  </page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmBeforeRouterLeave from '../mixins/ConfirmBeforeRouterLeave'
import PageLayout from '../layouts/PageLayout'
import UserGroup from '../app/models/UserGroup'
import { replace } from 'lodash'
import { required } from 'vuelidate/lib/validators'
import store from '@/store'

export default {
  name: 'UserGroupAdd',
  components: {
    PageLayout
  },
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    const corporateAccountOspId = store.getters['corporateAccount/selectedCorporateAccount'].ospId
    return {
      model: new UserGroup({
        data: {
          active: true,
          corporateAccount: {
            ospId: corporateAccountOspId
          }
        }
      }),
      key: '',
      saving: false,
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      allUserGroups: 'userGroup/all',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    })
  },
  validations () {
    return {
      model: {
        data: {
          extId: {
            required,
            unique (value) {
              const string = replace(value, /\s/g, '_')
              return !this.allUserGroups.find(v => v.data.extId === string)
            }
          },
          description: {
            required
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      findAll: 'userGroup/findAll'
    }),
    handleSaveChanges () {
      this.$v.model.data.$touch()
      if (this.$v.model.data.$invalid) {
        return true
      }
      this.saving = true
      this.model.save({
        successNotification: this.$t('user_group_management.user_group_add_page.success_notification'),
        onSuccess: userGroup => {
          this.dirty = false
          this.saving = false
          return this.$router.push({
            name: 'UserGroupMembers',
            params: {
              userGroupOspId: userGroup.ospId
            }
          })
        },
        onFinally: () => {
          this.saving = false
        }
      })
    }
  },
  async mounted () {
    this.findAll(this.corporateAccount.ospId)
    this.key = this.$key()
    this.$watch('model', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>
