<template>
  <base-section
    :title="$t('general.label.members')"
    :is-flex="true"
  >
    <base-transfer
      v-loading="loading"
      ref="transfer"
      :key="key"
      :top-titles="[$t('user_group_management.members_page.transfer.left_panel_title'), $t('user_group_management.members_page.transfer.right_panel_title')]"
      :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
      filterable
      :with-pagination="true"
      :filter-method="filterMethod"
      :value="transferValues"
      :right-pagination="members"
      :left-pagination="notMembers"
      :height="'auto'"
      :data="transferData"
      :left-panel-loading="leftPanelLoading"
      :right-panel-loading="rightPanelLoading"
      :full-width="true"
      :emit-transfer="true"
      @transfer="transfer"
    >
    </base-transfer>
  </base-section>
</template>

<script>
import ViewUserGroup from '../mixins/ViewUserGroup'
import { get, uniqBy, debounce } from 'lodash'

export default {
  name: 'UserGroupMembers',
  mixins: [ViewUserGroup],
  data () {
    return {
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false,
      selectedUserGroups: [],
      form: {},
      members: null,
      notMembers: null,
      leftPanelLoading: false,
      rightPanelLoading: false
    }
  },
  computed: {
    transferData () {
      return uniqBy(this.membersTransferData.concat(this.notMembersTransferData), 'key')
    },
    membersTransferData () {
      return (this.members && this.members.content && this.members.content.map(user => user.transferData)) || []
    },
    notMembersTransferData () {
      return (this.notMembers && this.notMembers.content && this.notMembers.content.map(user => user.transferData)) || []
    },
    transferValues () {
      return (this.members && this.members.content && this.members.content.map(user => user.ospId)) || []
    }
  },
  methods: {
    async fetchData () {
      this.loading = true
      try {
        const promises = [
          this.fetchMembers(),
          this.fetchNotMembers()
        ]
        await Promise.all(promises)
      } catch (e) {
        console.log(e)
      } finally {
        // this.key = this.$key()
        this.loading = false
      }
    },
    fetchMembers (page = 0) {
      this.rightPanelLoading = true
      return this.userGroup.fetchMembers(
        {
          active: true,
          pagination: {
            page,
            size: 25
          },
          search: get(this.$refs, ['transfer', '$refs', 'rightPanel', 'query'], '')
        }, {
          onSuccess: response => {
            response.paginationHandler = this.fetchMembers
            this.members = response
          },
          onFinally: () => {
            this.rightPanelLoading = false
          }
        }
      )
    },
    fetchNotMembers (page = 0) {
      this.leftPanelLoading = true
      return this.userGroup.fetchNotMembers(
        {
          active: true,
          pagination: {
            page,
            size: 25
          },
          search: get(this.$refs, ['transfer', '$refs', 'leftPanel', 'query'], '')
        },
        {
          onSuccess: response => {
            response.paginationHandler = this.fetchNotMembers
            this.notMembers = response
          },
          onFinally: () => {
            this.leftPanelLoading = false
          }
        }
      )
    },
    filterMethod (query, item) {
      return true
    },
    transfer (direction) {
      this.loading = true
      if (direction === 'right') {
        const users = get(this.$refs, ['transfer', 'leftChecked'], []).slice()
        this.userGroup.addUsers(users.map(v => {
          return { ospId: v }
        }), {
          onSuccess: () => {
            this.fetchData()
          },
          onFinally: () => {
            this.loading = false
          },
          successNotification: this.$t('user_group_management.members_page.success_notification.add')
        })
      } else {
        const users = get(this.$refs, ['transfer', 'rightChecked'], []).slice()
        this.userGroup.removeUsers(users.map(v => {
          return { ospId: v }
        }), {
          onSuccess: () => {
            this.fetchData()
          },
          onFinally: () => {
            this.loading = false
          },
          successNotification: this.$t('user_group_management.members_page.success_notification.remove')
        })
      }
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.fetchData()
      this.$watch('$refs.transfer.$refs.leftPanel.query', debounce(function () {
        this.fetchNotMembers()
      }, 700))
      this.$watch('$refs.transfer.$refs.rightPanel.query', debounce(function () {
        this.fetchMembers()
      }, 700))
    })
  }
}
</script>
