<template>
  <el-form ref="form" :model="model" @submit.prevent.native="null" :key="key" autocomplete="off" id="appUserForm"
           label-position="top"
           v-loading="loading">
    <base-input
      v-if="model"
      type="textarea"
      :label="$t('general.input_label.description')"
      maxlength="140"
      :autosize="{minRows: 5, maxRows: 8}"
      v-model="model.description"
      prop="description"
      name="description"
      :show-word-limit="true"
      :placeholder="$t('user_management.view_app_users_page.add_expense.input_description.placeholder', {number: 140})"
      :max-width="400"
    />
  </el-form>
</template>

<script>
import ViewUserGroup from '../mixins/ViewUserGroup'
import { mapActions } from 'vuex'
import ConfirmBeforeRouterLeave from '../mixins/ConfirmBeforeRouterLeave'

export default {
  name: 'UserGroupDetails',
  mixins: [ViewUserGroup, ConfirmBeforeRouterLeave],
  data () {
    return {
      model: null,
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false
    }
  },
  methods: {
    ...mapActions({
      getByOspId: 'userGroup/getByOspId'
    }),
    handleSaveChanges () {
      this.saving = true
      this.model.save({
        successNotification: this.$t('user_group_management.details_page.success_notification'),
        onSuccess: () => {
          this.getByOspId(this.userGroupOspId)
        },
        onFinally: () => {
          this.saving = false
          this.dirty = false
        }
      })
    }
  },
  async mounted () {
    this.model = this.userGroup.clone()
    this.key = this.$key()
    this.$watch('model.data', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>
