<template>
  <el-form
    ref="form"
    :model="form"
    @submit.prevent.native="null"
    :key="key"
    autocomplete="off"
    id="appUserForm"
    label-position="top"
    v-loading="loading"
  >
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('user_group_management.products_page.alert')"></span>
    </el-alert>
    <div v-if="showSwitch" :style="{ marginTop: '32px' }">
      <base-switch
        v-model="isAdvanced"
        :label="$t('products_management.products.simple_advanced_switch')"
        large-label
      />
    </div>

    <service-resources-section-advanced
      v-if="isAdvanced"
      :service-resources="corporateProducts"
      :group-products-advanced="groupProductsAdvanced"
      :budget-types="budgetTypes"
      @advancedHandler="advancedHandler"
    />

    <service-resources-section
      v-else
      :service-resources="corporateProducts"
      :edit-list="groupProducts"
      @add="addToGroup"
      @revoke="revokeFromGroup"
    >
      <template v-slot:footer="{ serviceResource }">
        <base-button
          v-if="serviceResourceIsAdded(serviceResource)"
          :loading="resourceButtonLoading[serviceResource.ospId]"
          prepend-icon="ri-check-line"
          type="success"
          @click="revokeFromGroup(serviceResource)"
        >
          {{ $t('user_group_management.products_page.product_added') }}
        </base-button>
        <base-button
          v-else-if="serviceResourceIsAddedByDefault(serviceResource)"
          class="is-muted"
          prepend-icon="ri-check-line"
          type="success"
          @click="addedByDefaultClickHandler"
        >
          {{ $t('user_group_management.products_page.product_added_by_default') }}
        </base-button>
        <base-button
          v-else
          :loading="resourceButtonLoading[serviceResource.ospId]"
          prepend-icon="ri-add-line"
          @click="addToGroup(serviceResource)"
        >
          {{ $t('user_group_management.products_page.add_product') }}
        </base-button>
      </template>
    </service-resources-section>
  </el-form>
</template>

<script>
import ViewUserGroup from '../mixins/ViewUserGroup'
import ServiceResourcesSection from '@/components/sections/ServiceResourcesSection'
import ServiceResourcesSectionAdvanced from '@/components/sections/ServiceResourcesSectionAdvanced'
import ServiceResourceRepository from '@/app/repositories/ServiceResourceRepository'
import { mapGetters } from 'vuex'
import { NOTIFICATION_CONFIG } from '@/app/helpers'

const serviceResourceRepository = new ServiceResourceRepository()

export default {
  name: 'UserGroupProducts',
  components: {
    ServiceResourcesSection,
    ServiceResourcesSectionAdvanced
  },
  mixins: [ViewUserGroup],
  data () {
    return {
      form: {},
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      corporateProducts: [],
      groupProducts: [],
      groupProductsAdvanced: null,
      defaultGroupProducts: [],
      resourceButtonLoading: {},
      isAdvanced: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount',
      defaultCompanyUserGroup: 'userGroup/defaultCompanyUserGroup'
    }),
    defaultGroup () {
      return this.defaultCompanyUserGroup(this.corporateAccount.extId)
    },
    budgetTypes () {
      return this.corporateAccount.budgetTypes
    },
    showSwitch () {
      return this.corporateAccount?.selectedBudgetTypes?.length > 1
    }
  },
  methods: {
    async fetchData () {
      const promises = []
      this.loading = true
      promises.push(
        serviceResourceRepository.findProductByUserGroup(this.userGroupOspId, {
          onSuccess: response => {
            this.groupProducts = response
          }
        })
      )
      promises.push(
        serviceResourceRepository.findByCorporateAccount(this.corporateAccount.ospId, {
          onSuccess: response => {
            this.corporateProducts = response
          }
        })
      )
      promises.push(
        serviceResourceRepository.findProductByUserGroup(this.defaultGroup.ospId, {
          onSuccess: response => {
            this.defaultGroupProducts = response.map(v => v.ospId)
          }
        })
      )
      await Promise.all(promises)
      this.loading = false
    },
    fetchGroupProductsAdvanced (options) {
      return serviceResourceRepository.findProductByUserGroupAdvanced(this.userGroupOspId, {
        onSuccess: response => {
          this.groupProductsAdvanced = response
        },
        ...options
      })
    },
    addToGroup (product) {
      this.resourceLoading(product.ospId, true)
      product.addToTarget(this.userGroupOspId, {
        onSuccess: () => {
          serviceResourceRepository.findProductByUserGroup(this.userGroupOspId, {
            onSuccess: response => {
              this.groupProducts = response
            },
            onFinally: () => {
              this.resourceLoading(product.ospId, false)
            }
          })
        },
        onError: () => {
          this.resourceLoading(product.ospId, false)
        },
        successNotification: this.$t('user_group_management.products_page.success_notification.add')
      })
    },
    revokeFromGroup (product) {
      this.resourceLoading(product.ospId, true)
      product.revokeFromTarget(this.userGroupOspId, {
        onSuccess: () => {
          serviceResourceRepository.findProductByUserGroup(this.userGroupOspId, {
            onSuccess: response => {
              this.groupProducts = response
            },
            onFinally: () => {
              this.resourceLoading(product.ospId, false)
            }
          })
        },
        onError: () => {
          this.resourceLoading(product.ospId, false)
        },
        successNotification: this.$t(
          'user_group_management.products_page.success_notification.revoke'
        )
      })
    },
    serviceResourceIsAdded (serviceResource) {
      return !!this.groupProducts.find(v => v.ospId === serviceResource.ospId)
    },
    serviceResourceIsAddedByDefault (serviceResource) {
      return !!this.defaultGroupProducts.includes(serviceResource.ospId)
    },
    resourceLoading (serviceResourceOspId, isLoading = false) {
      this.$set(this.resourceButtonLoading, serviceResourceOspId, isLoading)
    },
    addedByDefaultClickHandler () {
      this.$notify({
        ...NOTIFICATION_CONFIG,
        type: 'warning',
        message: this.$t('user_group_management.products_page.product_default_warning')
      })
    },
    advancedHandler ({ product, isEnabled, budgetType }) {
      this.loading = true
      const form = {
        assigneeOspId: this.userGroup.ospId,
        assigneeContextOspId: `OlympusMobility.BUDGET_TYPE.${budgetType}`
      }
      const options = {
        onSuccess: () => {
          this.fetchGroupProductsAdvanced({
            onFinally: () => {
              this.loading = false
            }
          })
        },
        onError: () => {
          this.loading = false
        }
      }
      if (isEnabled) {
        product.addToTarget(form, options)
      } else {
        product.revokeFromTarget(form, options)
      }
    }
  },
  watch: {
    async isAdvanced (v) {
      if (v) {
        this.loading = true
        await this.fetchGroupProductsAdvanced({
          onFinally: () => {
            this.loading = false
          }
        })
      } else {
        await this.fetchData()
      }
    }
  },
  async mounted () {
    await this.fetchData()
  }
}
</script>
